import { PatientServingDTO } from "../../../../../../../../models/patient-serving-dto";
import {
  NutritionSummaryCardFooter,
  NutritionSummaryCardFooterTwo,
} from "../styled-nutrition-summary";
import { MacrosSummary } from "./macros-summary";
import { FatsSummary } from "./fats-summary";
import { FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART } from "../../../../../../../../constants/config";

export type NutritionSummaryFooterProps = {
  datePatientServingDTOs: PatientServingDTO[];
};

export function NutritionSummaryFooter(props: NutritionSummaryFooterProps) {
  const { datePatientServingDTOs } = props;

  return (
    <>
      {FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART ? (
        <NutritionSummaryCardFooterTwo>
          <FatsSummary datePatientServingDTOs={datePatientServingDTOs} />
          <MacrosSummary datePatientServingDTOs={datePatientServingDTOs} />
        </NutritionSummaryCardFooterTwo>
      ) : (
        <NutritionSummaryCardFooter>
          <FatsSummary datePatientServingDTOs={datePatientServingDTOs} />
          <MacrosSummary datePatientServingDTOs={datePatientServingDTOs} />
        </NutritionSummaryCardFooter>
      )}
    </>
  );
}
