import { PatientServingDTO } from "../../../../../../../../../models/patient-serving-dto";

export type CalculateFatsSummaryReturn = {
  saturatedFatsPercentage: number;
  transFatsPercentage: number;
};

export function calculateFatsSummary(
  patientServingDTOs: PatientServingDTO[]
): CalculateFatsSummaryReturn {
  let totalEnergy = 0,
    saturatedFatsEnergy = 0,
    transFatsEnergy = 0;

  for (const patientServingDTO of patientServingDTOs) {
    const nutrition = patientServingDTO.nutrition;

    totalEnergy += nutrition.energyKCal ?? 0;
    saturatedFatsEnergy += (nutrition.saturatedG ?? 0) * 9;
    transFatsEnergy += (nutrition.transFatsG ?? 0) * 9;
  }

  let saturatedFatsPercentage = 0,
    transFatsPercentage = 0;

  // Using `totalEnergy` here instead of `totalEnergyFromMacros` like in macros-summary-utils.tsx is due to business
  // requirements.
  if (totalEnergy !== 0) {
    saturatedFatsPercentage = (saturatedFatsEnergy / totalEnergy) * 100;
    transFatsPercentage = (transFatsEnergy / totalEnergy) * 100;
  }

  return { saturatedFatsPercentage, transFatsPercentage };
}

export function isGoodSaturatedFatsPercentage(saturatedFatsPercentage: number) {
  return saturatedFatsPercentage < 7;
}

export function isGoodTransFatsPercentage(transFatsPercentage: number) {
  return transFatsPercentage < 1;
}
