import { PatientServingDTO } from "../../../../../../../../models/patient-serving-dto";
import { useMemo } from "react";
import { calculateMacrosSummary } from "./utils/macros-summary-utils";
import {
  Carbs,
  Fat,
  NutritionSummaryCardFooterBottom,
  NutritionSummaryCardFooterCenter,
  NutritionSummaryCardFooterTop,
  NutritionSummaryCardLegendPoint,
  PieChart,
  Protien,
  ToolTip,
  TootlTipText,
} from "../styled-nutrition-summary";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART } from "../../../../../../../../constants/config";

export type MacrosSummaryProps = {
  datePatientServingDTOs: PatientServingDTO[];
};

export function MacrosSummary(props: MacrosSummaryProps) {
  const { datePatientServingDTOs } = props;

  const { totalEnergy, carbsPercentage, fatPercentage, proteinPercentage } =
    useMemo(
      () => calculateMacrosSummary(datePatientServingDTOs),
      [datePatientServingDTOs]
    );

  return (
    <>
      {FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART ? (
        <>
          <PieChart
            carbs={carbsPercentage}
            fat={carbsPercentage + fatPercentage}
            protien={carbsPercentage + fatPercentage + proteinPercentage}
            kcal={`${roundTo1DecimalPlace(totalEnergy)}`}
          />
          <ToolTip>
            <p>Calories Consumed (kcal)</p>
            <TootlTipText>
              <p>Carbs</p>
              <span>{roundTo1DecimalPlace(carbsPercentage)}%</span>
            </TootlTipText>
            <TootlTipText>
              <p>Fat</p>
              <span>
                {roundTo1DecimalPlace(carbsPercentage + fatPercentage)}%
              </span>
            </TootlTipText>
            <TootlTipText>
              <p>Protein</p>
              <span>
                {roundTo1DecimalPlace(
                  carbsPercentage + fatPercentage + proteinPercentage
                )}
                %
              </span>
            </TootlTipText>
            <NutritionSummaryCardFooterBottom>
              <NutritionSummaryCardLegendPoint>
                <div />
                <span>Carbs</span>
              </NutritionSummaryCardLegendPoint>
              <NutritionSummaryCardLegendPoint>
                <div />
                <span>Fat</span>
              </NutritionSummaryCardLegendPoint>
              <NutritionSummaryCardLegendPoint>
                <div />
                <span>Protien</span>
              </NutritionSummaryCardLegendPoint>
            </NutritionSummaryCardFooterBottom>
          </ToolTip>
        </>
      ) : (
        <>
          <NutritionSummaryCardFooterTop>
            <p>Macronutrients</p>
            <span>{roundTo1DecimalPlace(totalEnergy)} Kcal</span>
          </NutritionSummaryCardFooterTop>
          <NutritionSummaryCardFooterCenter>
            <Carbs width={`${carbsPercentage}%`} />
            <Fat width={`${carbsPercentage + fatPercentage}%`} />
            <Protien
              width={`${carbsPercentage + fatPercentage + proteinPercentage}%`}
            />
          </NutritionSummaryCardFooterCenter>
          <NutritionSummaryCardFooterBottom>
            <div>
              <div />
              <span>Carbs</span>
            </div>
            <div>
              <div />
              <span>Fat</span>
            </div>
            <div>
              <div />
              <span>Protien</span>
            </div>
          </NutritionSummaryCardFooterBottom>
        </>
      )}
    </>
  );
}
