import { Temporal } from "temporal-polyfill";
import { useGetPatientServingsMealResponses } from "../../../../../hooks/use-get-patient-servings-meal-responses";
import { Loading } from "../../../../../components/loading";
import { useCallback } from "react";
import { DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES } from "../../../../../constants/daily-meals-graphs-constants";
import { DailyMealGraphsResponse } from "./daily-meal-graphs-response";
import { useParams } from "react-router-dom";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";
import { PatientServingsMealResponseDTO } from "../../../../../models/patient-servings-meal-response-dto";

type DailyMealGraphsCardsProps = {
  date: Temporal.PlainDate;
};

export type patientMealResponse = {
  patientDTO: PatientDTO;
  patientMealResponses: PatientServingsMealResponseDTO[];
};

export const DailyMealGraphsCards = (props: DailyMealGraphsCardsProps) => {
  const { id } = useParams();

  const { date } = props;

  const getPatient = useGetPatient();
  const getPatientMealResponses = useGetPatientServingsMealResponses();

  const load = useCallback(async () => {
    const [patientDTO, patientMealResponses] = await Promise.all([
      getPatient(Number(id!)),
      getPatientMealResponses(
        Number(id!),
        date.toString(),
        date.toString(),
        DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES
      ),
    ]);

    return { patientDTO, patientMealResponses };
  }, [getPatientMealResponses, id, date, getPatient]);

  return (
    <Loading
      load={load}
      successComponent={(patientMealResponse) => (
        <DailyMealGraphsResponse
          patientMealResponse={patientMealResponse}
          hasToolTip={true}
          smallModalGraphs={false}
        />
      )}
    />
  );
};
