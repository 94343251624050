import { PatientServingDTO } from "../../../../../../../../../models/patient-serving-dto";

export type CalculateMacrosSummaryReturn = {
  totalEnergy: number;
  carbsPercentage: number;
  fatPercentage: number;
  proteinPercentage: number;
};

export function calculateMacrosSummary(
  patientServingDTOs: PatientServingDTO[]
): CalculateMacrosSummaryReturn {
  let totalEnergy = 0,
    carbsEnergy = 0,
    proteinEnergy = 0,
    fatEnergy = 0;

  for (const patientServingDTO of patientServingDTOs) {
    const nutrition = patientServingDTO.nutrition;

    totalEnergy += nutrition.energyKCal ?? 0;
    carbsEnergy += (nutrition.netCarbsG ?? 0) * 4;
    fatEnergy += (nutrition.fatG ?? 0) * 9;
    proteinEnergy += (nutrition.proteinG ?? 0) * 4;
  }

  let carbsPercentage = 0,
    fatPercentage = 0,
    proteinPercentage = 0;
  const totalEnergyFromMacros = carbsEnergy + fatEnergy + proteinEnergy;
  if (totalEnergyFromMacros !== 0) {
    carbsPercentage = (carbsEnergy / totalEnergyFromMacros) * 100;
    fatPercentage = (fatEnergy / totalEnergyFromMacros) * 100;
    proteinPercentage = (proteinEnergy / totalEnergyFromMacros) * 100;
  }

  return { totalEnergy, carbsPercentage, fatPercentage, proteinPercentage };
}
