import {
  NutritionSummaryCard,
  NutritionSummaryCardHeader,
} from "./styled-nutrition-summary";
import { Temporal } from "temporal-polyfill";
import { useMemo } from "react";
import { NutritionSummaryCenter } from "./nutrition-summary-center";
import { NutritionSummaryFooter } from "./nutrition-summary-footer/nutrition-summary-footer";
import { useGetPatientServingDTOs } from "../../../../../../../components/cgm-google-chart/hooks/use-get-patient-serving-dtos";

export type NutritionSummaryProps = {
  date: Temporal.PlainDate;
};

export const NutritionSummary = (props: NutritionSummaryProps) => {
  const { date } = props;

  const { patientServingDTOs } = useGetPatientServingDTOs();

  const datePatientServingDTOs = useMemo(
    () =>
      patientServingDTOs.filter((patientServingDTO) =>
        Temporal.PlainDate.from(patientServingDTO.date).equals(date)
      ),
    [date, patientServingDTOs]
  );

  return (
    <NutritionSummaryCard>
      <NutritionSummaryCardHeader>
        <h3>Nutrition summary</h3>
        <p>Food groups</p>
      </NutritionSummaryCardHeader>
      <NutritionSummaryCenter datePatientServingDTOs={datePatientServingDTOs} />
      <NutritionSummaryFooter datePatientServingDTOs={datePatientServingDTOs} />
    </NutritionSummaryCard>
  );
};
