export const OTIDA_CATEGORIES_NAMES = [
  "Grains",
  "Legumes",
  "Fruits",
  "Vegetables",
  "Dairy",
  "Meats",
  "Oils and Fats",
  "Bakeries & Sweets",
  "Caffeinated Beverages",
  "Other Foods",
] as const;

export type OtidaCategoryName = typeof OTIDA_CATEGORIES_NAMES[number];

export type OtidaCategoryInfo = {
  oneServing?: { macro: "carbs" | "fat" | "protein"; numberOfGrams: number };
};

export const OTIDA_CATEGORIES: Record<OtidaCategoryName, OtidaCategoryInfo> = {
  Grains: { oneServing: { macro: "carbs", numberOfGrams: 15 } },
  Legumes: { oneServing: { macro: "carbs", numberOfGrams: 15 } },
  Fruits: { oneServing: { macro: "carbs", numberOfGrams: 15 } },
  "Other Foods": { oneServing: { macro: "carbs", numberOfGrams: 15 } },
  "Bakeries & Sweets": {
    oneServing: { macro: "carbs", numberOfGrams: 15 },
  },
  Vegetables: { oneServing: { macro: "carbs", numberOfGrams: 5 } },
  Dairy: { oneServing: { macro: "carbs", numberOfGrams: 12 } },
  Meats: { oneServing: { macro: "protein", numberOfGrams: 7 } },
  "Oils and Fats": { oneServing: { macro: "fat", numberOfGrams: 5 } },
  "Caffeinated Beverages": {},
};

export const CRONOMETER_TO_OTIDA_CATEGORY: Record<string, OtidaCategoryName> = {
  "Breakfast Cereals": "Grains",
  "Baked Products": "Grains",
  "Cereal Grains and Pasta": "Grains",
  "Legumes and Legume Products": "Legumes",
  "Fruits and Fruit Juices": "Fruits",
  "Fast Foods": "Other Foods",
  "Soups, Sauces, and Gravies": "Other Foods",
  Snacks: "Other Foods",
  Sweets: "Bakeries & Sweets",
  "Vegetables and Vegetable Products": "Vegetables",
  "Dairy and Egg Products": "Dairy",
  "Beef Products": "Meats",
  "Lamb, Veal, and Game Products": "Meats",
  "Poultry Products": "Meats",
  "Sausages and Luncheon Meats": "Meats",
  "Pork Products": "Meats",
  "Finfish and Shellfish Products": "Meats",
  "Fats and Oils": "Oils and Fats",
  "Nut and Seed Products": "Oils and Fats",
  Beverages: "Caffeinated Beverages",
};
