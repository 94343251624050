import {
  NutritionSummaryCardCenter,
  NutritionSummaryCardCenterList,
  NutritionSummaryCardCenterListItem,
} from "./styled-nutrition-summary";
import { PatientServingDTO } from "../../../../../../../models/patient-serving-dto";
import { useMemo } from "react";
import { calculateOtidaCategoriesServingsCounts } from "./utils/servings-utils";
import { OTIDA_CATEGORIES_NAMES } from "./utils/otida-categories-data";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";

export type NutritionSummaryCenterProps = {
  datePatientServingDTOs: PatientServingDTO[];
};

export function NutritionSummaryCenter(props: NutritionSummaryCenterProps) {
  const { datePatientServingDTOs } = props;

  const categoryToServingCount = useMemo(
    () => calculateOtidaCategoriesServingsCounts(datePatientServingDTOs),
    [datePatientServingDTOs]
  );

  const categoriesWithServings = OTIDA_CATEGORIES_NAMES.map(
    (otidaCategoryName) => (
      <NutritionSummaryCardCenterListItem key={otidaCategoryName}>
        <span>
          {roundTo1DecimalPlace(categoryToServingCount[otidaCategoryName] ?? 0)}
        </span>
        <p>{otidaCategoryName}</p>
      </NutritionSummaryCardCenterListItem>
    )
  );

  const left = categoriesWithServings.slice(0, 5);
  const right = categoriesWithServings.slice(5, 10);

  return (
    <NutritionSummaryCardCenter>
      <NutritionSummaryCardCenterList>
        <div>{left}</div>
        <div>{right}</div>
      </NutritionSummaryCardCenterList>
    </NutritionSummaryCardCenter>
  );
}
