import { useState } from "react";
import { Temporal } from "temporal-polyfill";
import { DailyMealGraphsCards } from "./daily-meal-graphs-cards/daily-meal-graphs-cards";
import { DailyMealGraphsHeader } from "./daily-meal-graphs-header/daily-meal-graphs-header";

export const DailyMealGraphs = () => {
  const [date, setDate] = useState(Temporal.Now.plainDateISO());

  return (
    <>
      <DailyMealGraphsHeader date={date} setDate={setDate} />
      <DailyMealGraphsCards date={date} />
    </>
  );
};
