import { PatientServingDTO } from "../../../../../../../../models/patient-serving-dto";
import { useMemo } from "react";
import {
  calculateFatsSummary,
  isGoodSaturatedFatsPercentage,
  isGoodTransFatsPercentage,
} from "./utils/fats-summary-utils";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import {
  FatSummaryContainer,
  FatSummaryText,
  FatSummaryTextTwo,
} from "../styled-nutrition-summary";
import { FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART } from "../../../../../../../../constants/config";

export type FatsSummaryProps = {
  datePatientServingDTOs: PatientServingDTO[];
};

export function FatsSummary(props: FatsSummaryProps) {
  const { datePatientServingDTOs } = props;

  const { saturatedFatsPercentage, transFatsPercentage } = useMemo(
    () => calculateFatsSummary(datePatientServingDTOs),
    [datePatientServingDTOs]
  );

  return (
    <>
      {FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART ? (
        <FatSummaryContainer>
          <FatSummaryTextTwo
            isGood={isGoodSaturatedFatsPercentage(saturatedFatsPercentage)}
          >
            <strong>{roundTo1DecimalPlace(saturatedFatsPercentage)}%</strong>
            <span>Saturated fats</span>
          </FatSummaryTextTwo>
          <FatSummaryTextTwo
            isGood={isGoodTransFatsPercentage(transFatsPercentage)}
          >
            <strong>{roundTo1DecimalPlace(transFatsPercentage)}%</strong>
            <span>Trans fats</span>
          </FatSummaryTextTwo>
        </FatSummaryContainer>
      ) : (
        <FatSummaryContainer>
          <FatSummaryText
            isGood={isGoodSaturatedFatsPercentage(saturatedFatsPercentage)}
          >
            Saturated fats:{" "}
            <span>{roundTo1DecimalPlace(saturatedFatsPercentage)}%</span>
          </FatSummaryText>
          <FatSummaryText
            isGood={isGoodTransFatsPercentage(transFatsPercentage)}
          >
            Trans fats:{" "}
            <span>{roundTo1DecimalPlace(transFatsPercentage)}%</span>
          </FatSummaryText>
        </FatSummaryContainer>
      )}
    </>
  );
}
