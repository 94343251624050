import { Temporal } from "temporal-polyfill";
import { DatePicker } from "../../../../../components/date-picker/date-picker";
import {
  DailyMealGraphsHeaderContainer,
  DailyMealGraphsHeaderRightContainer,
  DailyMealGraphsHeaderTitle,
} from "./styled-daily-meal-graphs-header";

export type DailyMealGraphsHeaderProps = {
  date: Temporal.PlainDate;
  setDate: (date: Temporal.PlainDate) => void;
};

export const DailyMealGraphsHeader = (props: DailyMealGraphsHeaderProps) => {
  const { date, setDate } = props;

  return (
    <DailyMealGraphsHeaderContainer>
      <DailyMealGraphsHeaderTitle>Daily Meal Graphs</DailyMealGraphsHeaderTitle>
      <DailyMealGraphsHeaderRightContainer>
        <DatePicker date={date} onChange={setDate} />
      </DailyMealGraphsHeaderRightContainer>
    </DailyMealGraphsHeaderContainer>
  );
};
