import {
  CRONOMETER_TO_OTIDA_CATEGORY,
  OTIDA_CATEGORIES,
  OtidaCategoryName,
} from "./otida-categories-data";
import { assertNever } from "../../../../../../../../utils/assert-never";
import { PatientServingDTO } from "../../../../../../../../models/patient-serving-dto";

export function calculateOtidaCategoriesServingsCounts(
  patientServingDTOs: PatientServingDTO[]
): Partial<Record<OtidaCategoryName, number>> {
  const categoryToServingCount: Partial<Record<OtidaCategoryName, number>> = {};

  for (const patientServingDTO of patientServingDTOs) {
    const otidaCategoryName =
      CRONOMETER_TO_OTIDA_CATEGORY[patientServingDTO.category];
    if (otidaCategoryName === undefined) {
      continue;
    }

    const categoryOneServing = OTIDA_CATEGORIES[otidaCategoryName].oneServing;
    if (categoryOneServing === undefined) {
      categoryToServingCount[otidaCategoryName] =
        (categoryToServingCount[otidaCategoryName] ?? 0) + 1;
      continue;
    }

    let macroNumberOfGrams: number;
    if (categoryOneServing.macro === "carbs") {
      // This assumes that Cronometer user profile is configured to "Net Carbs = Total Carbs - Fiber - Sugar Alcohols"
      let sugarAlcohols =
        patientServingDTO.nutrition.carbsG! -
        patientServingDTO.nutrition.netCarbsG! -
        (patientServingDTO.nutrition.fiberG ?? 0);

      // Due to rounding errors, sugarAlcohols might be negative.
      // Not that it'd matter much in the calculation, though, since it'd be of the order of 10^-15.
      if (sugarAlcohols < 0) {
        sugarAlcohols = 0;
      }

      macroNumberOfGrams =
        patientServingDTO.nutrition.carbsG! - sugarAlcohols + sugarAlcohols / 2;
    } else if (categoryOneServing.macro === "fat") {
      macroNumberOfGrams = patientServingDTO.nutrition.fatG!;
    } else if (categoryOneServing.macro === "protein") {
      macroNumberOfGrams = patientServingDTO.nutrition.proteinG!;
    } else {
      assertNever(categoryOneServing.macro);
    }
    const numberOfServings =
      macroNumberOfGrams / categoryOneServing.numberOfGrams;

    categoryToServingCount[otidaCategoryName] =
      (categoryToServingCount[otidaCategoryName] ?? 0) + numberOfServings;
  }

  return categoryToServingCount;
}
